import { GLOBAL_CONSTANTS } from '../utils/constants'
import Emitter from '../utils/emitter'

const CLASSES = {
    YOUTUBE_VIDEO: '.youtube-video'
}

export default class YouTubeVideo {
    constructor(el) {
        this.el = el

        this.videoPlayerEl = this.el

        this.initializeVideoConfig = this.initializeVideoConfig.bind(this)

        this.loadScripts()
        this.registerListeners()
    }

    loadScripts() {
        if (this.videoPlayerEl && !window.YT) {
            window.onYouTubeIframeAPIReady = () => {
                Emitter.emit('onYouTubeIframeAPIReady')
            }

            Emitter.on('onYouTubeIframeAPIReady', () => {
                this.initializeVideoConfig()
            })

            this.generateScript(GLOBAL_CONSTANTS.URLS.YOUTUBE_API_URL)
        }
    }

    generateScript(URL, callback) {
        const tag = document.createElement('script')
        tag.onload = callback
        tag.src = URL

        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }

    registerListeners() {
        const buttons = this.el.parentNode.querySelectorAll('button')
        const iframeParent = this.el.parentNode
        buttons.forEach((button) => {
            button.addEventListener('click', () => {
                const iframe = iframeParent.querySelector('iframe')
                iframe.style.display = 'initial'
                button.style.display = 'none'
                this.player.playVideo()
            })
        })
    }

    initializeVideoConfig() {
        this.videoID = this.videoPlayerEl.dataset.id
        this.playerConfig = {
            videoId: this.videoID,
            playerVars: {
                autoplay: 0, // Auto-play the video on load
                controls: 1, // Show pause/play buttons in player
                showinfo: 0, // Hide the video title
                modestbranding: 1, // Hide the Youtube Logo
                fs: 1, // Hide the full screen button
                // Hide closed captions
                cc_load_policy: 0, // eslint-disable-line camelcase
                // Hide the Video Annotations
                iv_load_policy: 3, // eslint-disable-line camelcase
                autohide: 0 // Hide video controls when playing
            },
            events: {
                onReady: function () { }
            }
        }

        this.player = new YT.Player(this.videoPlayerEl, this.playerConfig)
    }
}

export const YouTubeVideoComponent = {
    'name': 'YouTube Player',
    'class': CLASSES.YOUTUBE_VIDEO,
    'Source': YouTubeVideo
}