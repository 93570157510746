import { GLOBAL_CONSTANTS } from '../utils/constants'
import Emitter from '../utils/emitter'
import axios from 'axios'
import getCookie from '../utils/getCookie'

const CLASSES = {
    COMPONENT: '.js-footer',
    FORM_WRAPPER: '.js-form-wrapper',
    SUBMIT_BUTTON: '.js-form-submit',
    FOOTER_FORM: '.js-footer-form',
    FOOTER_FORM_INPUT_WRAPPER: '.js-footer-form-input-wrapper',
    FOOTER_FORM_SUCCESS: '.js-form-success',
    ACTIVATE_FORM: '.activate-footer-form',
    INACTIVE_FORM: '.inactive-footer-form',
    FORM_HEADLINE: '.footer__-form-headline',
    FOOTER_HEADLINE: '.footer__-main-headline'
}

export default class Footer {
    constructor(el) {
        this.el = el
        this.modalForm = this.el.querySelector(CLASSES.FOOTER_FORM)
        this.modalFormInputWrapper = this.el.querySelector(CLASSES.FOOTER_FORM_INPUT_WRAPPER)
        this.formWrapper = this.el.querySelector(CLASSES.FORM_WRAPPER)
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.submitButton = this.el.querySelector(CLASSES.SUBMIT_BUTTON)
        this.activateForm = this.el.querySelector(CLASSES.ACTIVATE_FORM)
        this.hideForm = this.el.querySelector(CLASSES.INACTIVE_FORM)
        this.formHeadline = this.el.querySelector(CLASSES.FORM_HEADLINE)
        this.footerHeadline = this.el.querySelector(CLASSES.FOOTER_HEADLINE)

        this.registerListeners()
    }

    registerListeners() {
        this.modalForm.addEventListener('submit', this.onFormSubmit)
        this.activateForm.addEventListener('click', () => {
            this.activateForm.style.display = 'none'
            this.showSubscribeForm()
        })
        this.hideForm.addEventListener('click', () => {
            this.activateForm.style.display = 'block'
            this.hideSubscribeForm()
        })
    }

    showSubscribeForm() {
        this.modalForm.style.display = "block"
        this.formHeadline.style.display = "block"
        this.footerHeadline.style.display = "none"
    }

    hideSubscribeForm() {
        this.modalForm.style.display = "none"
        this.activateForm.style.display = 'block'
        this.formHeadline.style.display = "none"
        this.footerHeadline.style.display = "block"
        this.modalForm.reset()
    }

    onFormSubmit(event) {
        event.preventDefault()

        this.submitButton.disabled = true
        const formData = new FormData(event.target)
        formData.set('csrfmiddlewaretoken', getCookie('csrftoken'))

        axios.post(GLOBAL_CONSTANTS.URLS.FOOTER_FORM_POST_URL, formData)
            .then((response) => {
                // Handle a successful query
                if (response.status === 200) {
                    this.modalForm.classList.add(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)
                    const formHeight = this.formWrapper.offsetHeight

                    setTimeout(() => {
                        const domParser = new DOMParser()
                        const parsedDocument = domParser.parseFromString(response.data, 'text/html')
                        this.formWrapper.innerHTML = parsedDocument.body.firstChild.innerHTML
                        const formSuccess = this.formWrapper.querySelector(CLASSES.FOOTER_FORM_SUCCESS)
                        formSuccess.style.height = `${formHeight}px`
                        formSuccess.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOWING)
                    }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
                }
            })
            .catch((error) => {
                // Handle an error
                this.submitButton.disabled = false
                if (error.response.status === 400) {
                    const domParser = new DOMParser()
                    const parsedDocument = domParser.parseFromString(error.response.data, 'text/html')
                    this.modalFormInputWrapper.innerHTML = parsedDocument.querySelector(CLASSES.FOOTER_FORM_INPUT_WRAPPER).innerHTML
                } else {
                    Emitter.emit('exception', {
                        error,
                        req: GLOBAL_CONSTANTS.URLS.FOOTER_FORM_POST_URL
                    })
                }
            })
    }

}

export const FooterComponent = {
    'name': 'Footer',
    'class': CLASSES.COMPONENT,
    'Source': Footer
}
